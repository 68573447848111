<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-title>
                <h3>
                  سندات القيود
                </h3>
              </b-card-title>

              <!-- Spacer -->
              <hr class="invoice-spacing">
              <b-card-body>
                <b-row>
                  <b-col
                    cols="6"
                    lg="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : null"
                      >
                        <label>
                          العملة
                        </label>
                        
                        <v-select
                          v-model="Form.currency_id"
                          :dir="
                            $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                          "
                          :options="currencyOption"
                          label="name"
                          :clearable="false"
                          :reduce="(val) => val.id"
                          class="mb-2 item-selector-title"
                         
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="6"
                    lg="6"
                  >
                  <validation-provider
                      #default="{ errors }"
                      rules=""
                    >
                    <b-form-group
                        :state="errors.length > 0 ? false : null"
                      >
                    <label class="">     التفاصيل  </label>
                    <b-form-textarea
                      v-model="Form.description"
                      type="text"

                      class="mb-2 mb-lg-0"
                    />
                    </b-form-group>
                    <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="6"
                  >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                    <b-form-group
                        :state="errors.length > 0 ? false : null"
                      >
                    <label class="">     التاريخ  </label>
                    <flat-pickr
                      v-model="Form.date"

                      class="form-control"
                    />
                    </b-form-group>
                    <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                </b-row>

              </b-card-body>
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">

                <div
                  ref="form"
                  class="repeater-form"
                >
              
                  <b-row
                    v-for="(item, index) in Form.entries"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label>
                              رقم الحساب
                            </label>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label>
                              المدين
                            </label>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label>
                              الدائن
                            </label>
                          </b-col>

                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label class="d-inline d-lg-none">
                              رقم الحساب</label>
                                <v-select
                                  v-model="item.account_id"
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  :options="SubAccountItem"
                                  label="name"
                                  :clearable="false"
                                  :reduce="(val) => val.id"
                                  class="mb-2 item-selector-title"
                                  placeholder="Select Item"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label class="d-inline d-lg-none">المدين</label>
                                <b-form-input
                                  v-model="item.debit"
                                  type="number"
                                  class="mb-2"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label class="d-inline d-lg-none">الدائن</label>
                                <b-form-input
                                  v-model="item.credit"
                                  type="number"
                                  class="mb-2"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>

                        </b-row>
                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            v-if="index != 0"
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  <span>
                    اضافة حساب اخرى
                  </span>
                </b-button>
                <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >المجموع
                          <label>
                            {{ AllDebit }}
                          </label>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >المجموع
                          <label>
                            {{ AllCredit }}
                          </label>
                        </b-col>

                      </b-row>
              </b-card-body>

              
            </b-card>
            <b-col
                class="border-Primary mb-10"
                md="12"
                xl="4"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  type="submit"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50 mb-10"
                  />
                  <span class="align-middle"> ارسال الطلب</span>
                </b-button>
              </b-col>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
          <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted ,computed} from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  bCardTitle,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import financeStoreModule from './financeStoreModule.js'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    bCardTitle,
    financeStoreModule,
    BCardTitle,
    BCol,
    BCard,
    BCardBody,
    required,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  // computed: {
  //   AllDebit() {
  //     //console.log('com')
  //     let all = 0
  //     this.Form.entries.forEach(el => {
  //       all += +el.debit
  //     })
  //     return all
  //   },
  //   AllCredit() {
  //     //console.log('com')
  //     let all = 0
  //     this.Form.entries.forEach(el => {
  //       all += +el.credit
  //     })
  //     return all
  //   },
  // },

  methods: {
    addNewItemInItemForm() {
      this.Form.entries.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )
    },
    removeItem(index) {
      this.Form.entries.splice(index, 1)
    },

  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const ItemData = ref([])
 //   var currentDateWithFormat = new Date().toJSON().slice(0, 10);
 let today = new Date();
 let dd = String(today.getDate()).padStart(2, '0');
 let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
 let yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;


    
    const PurchesItem = ref([])
    const SubAccountItem = ref([])
    const currencyOption = ref([])
    store.dispatch('app-finance/GetSubAccounts').then(response => {
      //console.log(',', response)
      SubAccountItem.value = response
    })
    store.dispatch('app-finance/GetCurrency').then(response => {
      //console.log('currencyOption,', response)
      currencyOption.value = response
    })
   
    const itemFormBlankItem = {
      account_id: '',
      debit: 0,
      credit: 0,

    }

    const Form = ref({

      description: '',
      currency_id: 1,
      date: '',
      entries: [
        {
          account_id: '',
          debit: 0,
          credit: 0,
        },

      ],

    })
    Form.value.date = today;
    const AllDebit = computed(() => {
      let all = 0
    Form.value.entries.forEach(el => {
        all += +el.debit
      })
    return all
    
  })
  const AllCredit = computed(() => {
      let all = 0
    Form.value.entries.forEach(el => {
        all += +el.credit
      })
    return all
    
  })
    const getAccountId = id => {
      const accountId = PurchesItem.value.find(el => el.id == id).transaction_id.account_id
      //console.log(accountId)
      store.dispatch('app-finance/getSubAccountById', accountId).then(response => {
        //console.log('currencyOption,', response)
        SubAccountItem.value = response
      })
    }
    const onSubmit = () => {
      //console.log(AllDebit)
      //console.log(AllCredit)
      if (AllDebit.value !== AllCredit.value) {
        Vue.swal({
          title: '',
          text: 'يجب ان يكون مجموع الدين يساوي مجموع الدائن',
          icon: 'error',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
       // console.log(Form.value)
        store.dispatch('app-finance/financeTransactions', Form.value).then(response => {
          Form.value.description = ''
          Form.value.currency_id = 1
          Form.value.date = today
        

          Form.value.entries = [{ account_id: '', debit: 0, credit: 0 }]
          resetForm()
          Vue.swal({
            title: '',
            // text: 'تمت أضافة  سند القيد بنجاح',
            text:response.data.message,
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }
    }

   
    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer']

    return {
      today,
      dd,
      mm,
      yyyy,
      AllDebit,
      AllCredit,
      SubAccountItem,
      getAccountId,
      currencyOption,
      onSubmit,
      refFormObserver,
      PurchesItem,
      resetForm,
      getValidationState,
      ItemData,
      Form,

      itemFormBlankItem,
      paymentMethods,
    }
  },
}
</script>

    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
    </style>

    <style lang="scss" scoped>
    @import "~@core/scss/base/pages/app-invoice.scss";

    .form-item-section {
      background-color: $product-details-bg;
    }

    label {
      color: #7e7a9f;
      font-weight: bold;
      font-family: "Cairo", sans-serif;
    }
    .form-item-action-col {
      width: 27px;
    }
    b-card-title {
      text-align: center;
      margin-top: 12px;
    }
    .repeater-form {
      // overflow: hidden;
      transition: 0.35s height;
    }
    .border-Primary.mb-10.col-md-12.col-xl-4 {
      margin-bottom: 21px;
    }
    .v-select {
      &.item-selector-title,
      &.payment-selector {
        background-color: #fff;

        .dark-layout & {
          background-color: unset;
        }
      }
    }

    .dark-layout {
      .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
          background-color: $theme-dark-card-bg;
        }
      }
    }
    </style>
